html {
  font-size: 100%;
}

html body #root .App {
  min-height: 100vh;
  height: 100%;
  background: #fff;
}

* {
  box-sizing: border-box;
}
:root:root {
  --adm-color-primary: #ffb206;
}
