// width: 100%;
// height: 100%;
// background-repeat: no-repeat;
// background-size: 100% 100%;
// height: inherit;
// padding-top: var(--safe-top);
// padding-bottom: var(--safe-bottom);
// }
// :global {
//   .S--focusing {
//     --safe-bottom: 0vh !important;

// @keyframes typing {
//   from {
//     width: 0;
//   }
// }
// @keyframes caret {
//   50% {
//     border-right-color: #000;
//   }
// }
// @keyframes typewriter {
//   to {
//     left: 100%;
//   }
// }

// @keyframes blink {
//   to {
//     background: transparent;
//   }
// }
// :root {
//   --bg-color: #fff;
//   --typewriterSpeed: 2s;
//   --typewriterCharacters: 15;
// }

// p {
//   // font-size: clamp(1rem, 2vw + 1rem, 4rem);
//   position: relative;
//   font-family: "Source Code Pro", monospace;
//   width: max-content;
// }

// p::before,
// p::after {
//   content: "";
//   width: 1ch;
//   height: 2ch;
//   display: inline-block;
//   vertical-align: text-top;
//   background-color: #222;
//   animation: svelte-1eqgw6m-cursorFade 1.25s infinite;
// }

// p::before {
//   background: var(--bg-color);
//   animation: typewriter var(--typewriterSpeed)
//     steps(var(--typewriterCharacters)) 0s forwards;
// }

// p::after {
//   width: 0.125em;
//   background: black;
//   animation: typewriter var(--typewriterSpeed)
//       steps(var(--typewriterCharacters)) 1s forwards,
//     blink 750ms steps(var(--typewriterCharacters)) infinite;
// }
// 执行typing动画，在5s内执行完毕，分15段执行
// 执行typing动画，在0.5s内执行完毕，分1段执行，循环执行10次
// p {
//   font-size: 16px;
//   width: 25em;
//   white-space: nowrap;
//   overflow: hidden;
//   animation: typing 2s steps(5), caret 0.2s steps(1) 3;
//   border-right: 1px solid transparent;
// }

// :global {
//   @media (hover: none) {
//     .S--focusing .MessageList {
//       margin-top: 68vh !important;
//     }
//   }
// }
// :global {
//   @media (hover: none) {
//     .S--focusing .MessageList {
//       margin-top: 68vh !important;
//     }
//   }
// }

@media (hover: none) {
  :global {
    .S--focusing .MessageList {
      margin-top: 68vh !important;
    }
  }
}
.chatGptTop {
  // :global {
  //   .MessageList {
  //     margin-top: 0vh !important;
  //   }
  // }
  height: 100vh !important;
  background: #eeeeee;
  :global {
    .Recorder-btn {
      font-weight: bold;
    }
    .Bubble.text {
      white-space: normal;
    }
  }
}

html {
  // .ChatFooter {
  //   position: unset !important;
  //   width: 100%;
  //   bottom: 0;
  //   z-index: 100;
  // }
  // @media (hover: none) {
  //   .S--focusing {
  //     .MessageList {
  //       margin-top: 68vh;
  //     }
  //   }
  // }
  .ChatApp {
    height: 100vh !important;
  }
  .App {
    background: #eeeeee;
  }
  // }
}

//   @media (hover: none) {
//     .S--focusing {
//       .MessageList {
//         margin-top: 0vh !important;
//       }
//     }
//   }
// }
